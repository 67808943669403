import Vue from "vue";


Vue.filter("change_message", function(value, removeString, addString) {
  return value ? value.replace(removeString, addString) : "";
});

Vue.filter("capitalize", function(value) {
  if (!value) return "";
  value = value.toString();
  let arr = value.split(" ");
  let capitalized_array = [];
  arr.forEach(word => {
    let capitalized = word.charAt(0).toUpperCase() + word.slice(1);
    capitalized_array.push(capitalized);
  });
  return capitalized_array.join(" ");
});



Vue.filter("phoneNumber", function(value) {
  if (value) {
    if(value.includes("+61")) {
      value = value.replace('+61','');
    }
    const x = value.replace(/\D/g, '').match(/(\d{4})(\d{3})(\d{3})/);
    return  `${x[1]} ${x[2]} ${x[3]}`
  }

});

Vue.filter("title", function(value, replacer = "_") {
  if (!value) return "";
  value = value.toString();

  let arr = value.split(replacer);
  let capitalized_array = [];
  arr.forEach(word => {
    let capitalized = word.charAt(0).toUpperCase() + word.slice(1);
    capitalized_array.push(capitalized);
  });
  return capitalized_array.join(" ");
});

Vue.filter("truncate", function(value, limit) {
  return value.substring(0, limit);
});

Vue.filter("tailing", function(value, tail) {
  return value + tail;
});

Vue.filter("time", function(value, is24HrFormat = false) {
  if (value) {
    const date = new Date(Date.parse(value));
    let hours = date.getHours();
    const min = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
    if (!is24HrFormat) {
      const time = hours > 12 ? "AM" : "PM";
      hours = hours % 12 || 12;
      return hours + ":" + min + " " + time;
    }
    return hours + ":" + min;
  }
});


Vue.filter("date", function(value, fullDate = false) {
  value = String(value);
  const date = value.slice(8, 10).trim();
  const month = value.slice(4, 7).trim();
  const year = value.slice(11, 15);

  if (!fullDate) return date + " " + month;
  else return date + " " + month + " " + year;
});

Vue.filter("month", function(val, showYear = true) {
  val = String(val);

  const regx = /\w+\s(\w+)\s\d+\s(\d+)./;
  if (!showYear) {
    return regx.exec(val)[1];
  } else {
    return regx.exec(val)[1] + " " + regx.exec(val)[2];
  }
});

Vue.filter("csv", function(value) {
  return value.join(", ");
});

Vue.filter("filter_tags", function(value) {
  return value.replace(/<\/?[^>]+(>|$)/g, "");
});

Vue.filter("k_formatter", function(num) {
  return num > 999 ? (num / 1000).toFixed(1) + "k" : num;
});

// Filter for date formatting
Vue.filter("date_formatter", function(date, format = null) {
  var weekdays = new Array(7);
  weekdays[0] = "Sun";
  weekdays[1] = "Mon";
  weekdays[2] = "Tue";
  weekdays[3] = "Wed";
  weekdays[4] = "Thu";
  weekdays[5] = "Fri";
  weekdays[6] = "Sat";

  let returnData = "";

  let dateObj = new Date(date);
  let day = weekdays[dateObj.getDay()];
  let year = dateObj.getFullYear();
  let month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
  let dateNum = ("0" + dateObj.getDate()).slice(-2);

  switch (format) {
    case "YYYY-MM-DD": {
      returnData = `${year}-${month}-${dateNum}`;
      break;
    }
    default: {
      returnData = day + " " + year + "-" + month + "-" + dateNum;
    }
  }

  return returnData;
});

Vue.filter("change_message", function(value, removeString, addString) {
  return value ? value.replace(removeString, addString) : "";
});
