import Vue from "vue";
import Router from "vue-router";
import store from "./store/store";
import { auth } from "@/auth/auth";
import { clearLocalStorage } from "./helper/localStorage.js";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      // for onboarding portal outside user login
      path: "",
      component: () => import("./layouts/CreateLogin/CreateLogin.vue"),
      children: [
        {
          path: "/",
          redirect: "/login",
        },
        {
          path: "/login",
          name: "onboarding-login",
          component: () =>
            import("@/views/onboarding/CustomerLogin/CustomerLogin.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/:introducerRequestedId/authenticate",
          name: "onboarding-authenticate",
          component: () =>
            import("@/views/authentication/Authenticate.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/:introducerRequestedId/verify-code",
          name: "onboarding-verify-code",
          component: () =>
            import("@/views/authentication/VerifyCode.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/:introducerRequestedId/set-password",
          name: "onboarding-set-password",
          component: () =>
            import("@/views/onboarding/CustomerLogin/SetPassword.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/reset-password-email/:token",
          name: "onboarding-reset-password",
          beforeEnter: (to, from, next) => {
            const payload = {
              token: to.params.token,
            };
            store
              .dispatch("onboardMerchant/verifyResetPassword", payload)
              .then(() => {
                return next();
              })
              .catch((error) => {
                return next("/pages/error-419");
              });
          },
          component: () =>
            import(
              "@/views/onboarding/CustomerLogin/RecoverCreatePassword.vue"
            ),
          meta: {
            rule: "editor",
          },
        },
      ],
    },

    {
      path: "/",
      component: () => import("@/layouts/portal/portal.vue"),
      children: [
        {
          path: "/legals/:type",
          name: "product-terms",
          component: () => import("@/views/onboarding/LegalsView"),
          meta: {
            rule: "editor",
            requiresAuth: false,
          },
        },
        {
          path: "/:introducerRequestedId/onboarding/:card?",
          name: "onboarding-form",
          component: () => import("@/views/onboarding/MerchantOnboard"),
          meta: {
            rule: "editor",
            requiresAuth: true,
          },
        },
        {
          path: "/review/:introducerRequestedId",
          name: "reviewApplication",
          component: () => import("@/views/onboarding/review"),
          meta: {
            rule: "editor",
            requiresAuth: true,
          },
        },
        {
          path: "/terms/:introducerRequestedId?",
          name: "termsAndCondition",
          component: () => import("@/views/onboarding/MerchantOnboard/terms"),
          meta: {
            rule: "editor",
            requiresAuth: true,
          },
        },
        {
          path: "/submission/:introducerRequestedId?",
          name: "SubmissionApplication",
          component: () => import("@/views/onboarding/review/Submission.vue"),
          meta: {
            rule: "editor",
            requiresAuth: true,
          },
        },
        {
          path: "/pages/error-419",
          name: "page-error-419",
          component: () => import("@/views/pages/Error419.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/idverification/:idvToken",
          name: "idverificationidvToken",
          beforeEnter: (to, from, next) => {
            const payload = {
              idvToken: to.params.idvToken
            };
            store
              .dispatch("onboardMerchant/verifyidvToken", payload)
              .then((response) => {
                const responeData = response.data.data;

                if (responeData.entityType == "individual" && responeData.isUsed === false) {
                  return next(`/idverification/${responeData.onboardingRequestId}/${responeData.uniqueId}/${responeData.entityType}?hash=${payload.idvToken}`);
                } else if (responeData.entityType == "company" && responeData.isUsed === false) {
                  return next(`/idverification/${responeData.onboardingRequestId}/${responeData.uniqueId}/${responeData.entityType}/${responeData.ownershipId}?hash=${payload.idvToken}`);
                } else {
                  return next(`pages/error-419`);
                }
              })
              .catch(ex => {
                return next(`pages/error-419`);
              });
          },
          component: () => import("@/views/onboarding/IdVerification"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/idverification/:onboardingRequestId?/:uniqueId?/:fieldName?/:ownershipId?",
          name: "merchantIdVerification",
          component: () => import("@/views/onboarding/IdVerification"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/error-404",
          name: "page-error-404",
          component: () => import("@/views/pages/Error404.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/error-500",
          name: "page-error-500",
          component: () => import("@/views/pages/Error500.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "*",
          name: "page-error-404",
          component: () => import("@/views/pages/Error404.vue"),
          meta: {
            rule: "editor",
          },
        },
      ],
    },
    {
      path: "/admin",
      component: () => import("@/layouts/portal/portal.vue"),
      children: [
        {
          path: "/",
          name: "application",
          component: () => import("@/views/onboardingAdmin/MerchantData"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/mvp-review/:onboardingId",
          name: "mvpReview",
          component: () => import("@/views/onboardingAdmin/ReviewData"),
          meta: {
            rule: "editor",
          },
        },
      ],
    },
  ],
});

router.afterEach((to) => {
  const appLoading = document.getElementById("loading-bg");

  if (appLoading) {
    appLoading.style.display = "none";
  }
  document.title = to.meta.pageTitle || process.env.VUE_APP_NAME;
});

// this method is calling in each router navigate
// commented cause of reset-password link doesn't required to auth
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    let onboardingInfo = JSON.parse(localStorage.getItem("onboardingUser"));
    const firstPath = to.path.split("/")[1];
    const currentPath = to.path.split("/");
    const hasPasswordBeenSet = false;

    // id of the introducer to get hasPasswordBeenSet
    store
      .dispatch("onboardMerchant/getOnboardRequestDetail", {
        introducerId: to.params.introducerRequestedId,
      })
      .then((response) => {
        const onboardingData = JSON.parse(localStorage.getItem("onboardingUser"));

        if (!onboardingData) {
          return next();
        }

        if (onboardingData._id != to.params.introducerRequestedId) {
          if (localStorage.getItem("accessToken")) {
            clearLocalStorage();
            window.location.href = `${process.env.VUE_APP_BASE_URL}${to.params.introducerRequestedId}/onboarding`;
          }
        }

        if (response.data.data.onboarding !== undefined && ["Approved", "Completed", "Awaiting approval", "Resubmitted"].includes(response.data.data.onboarding.status) && currentPath.includes("onboarding")) {
          return next(`/submission/${response.data.data._id}`);
        }

        return next();
      })
      .catch((ex) => {
        return next("/pages/error-419");
      });

    if (firstPath === "admin" && onboardingInfo && onboardingInfo.role !== "admin") {
      return next({ path: "/dashboard/" });
    }

    auth(to, from, next, hasPasswordBeenSet);
  } else {
    return next();
  }
});

export default router;
