import state from "./moduleOnboardState";
import mutations from "./moduleOnboardMutation";
import actions from "./moduleOnboardAction";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
