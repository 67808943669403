import jwt from "../../http/requests/auth/jwt/index.js";
import router from "@/router";
import httpStatusCode from "http-status-codes";
import axios from "@/axios";
import Vue from "vue";
import VueCookies from "vue-cookies";
import { clearLocalStorage } from "../../helper/localStorage.js";
import { use } from "vee-validate/dist/vee-validate.minimal.esm";

Vue.use(VueCookies);

export default {
  login({ commit, state, dispatch }, payload) {
    if (state.isUserLoggedIn()) {
      if (payload.closeAnimation) {
        payload.closeAnimation();
      }
      payload.notify({
        title: "Login Attempt",
        text: "You are already logged in!",
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "warning"
      });

      return false;
    }

    firebase
      .auth()
      .signInWithEmailAndPassword(
        payload.userDetails.email,
        payload.userDetails.password
      )

      .then(
        result => {
          // Set FLAG username update required for updating username
          let isUsernameUpdateRequired = false;

          // if username is provided and updateUsername FLAG is true
          // set local username update FLAG to true
          // try to update username
          if (payload.updateUsername && payload.userDetails.displayName) {
            isUsernameUpdateRequired = true;

            dispatch("updateUsername", {
              user: result.user,
              username: payload.userDetails.displayName,
              notify: payload.notify,
              isReloadRequired: true
            });
          }

          // Close animation if passed as payload
          if (payload.closeAnimation) {
            payload.closeAnimation();
          }

          // if username update is not required
          // just reload the page to get fresh data
          // set new user data in localstorage
          if (!isUsernameUpdateRequired) {
            router.push(router.currentRoute.query.to || "/");
            commit("UPDATE_USER_INFO", result.user.providerData[0], {
              root: true
            });
          }
        },

        err => {
          // Close animation if passed as payload
          if (payload.closeAnimation) payload.closeAnimation();

          payload.notify({
            time: 2500,
            title: "Error",
            text: err.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        }
      );
  },

  // Google Login
  loginWithGoogle({ commit, state }, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: "Login Attempt",
        text: "You are already logged in!",
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "warning"
      });
      return false;
    }

    const provider = new firebase.auth.GoogleAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(result => {
        router.push(router.currentRoute.query.to || "/");
        commit("UPDATE_USER_INFO", result.user.providerData[0], { root: true });
      })
      .catch(err => {
        payload.notify({
          time: 2500,
          title: "Error",
          text: err.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger"
        });
      });
  },

  // Facebook Login
  loginWithFacebook({ commit, state }, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: "Login Attempt",
        text: "You are already logged in!",
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "warning"
      });
      return false;
    }
    const provider = new firebase.auth.FacebookAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(result => {
        router.push(router.currentRoute.query.to || "/");
        commit("UPDATE_USER_INFO", result.user.providerData[0], { root: true });
      })
      .catch(err => {
        payload.notify({
          time: 2500,
          title: "Error",
          text: err.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger"
        });
      });
  },

  // Twitter Login
  loginWithTwitter({ commit, state }, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: "Login Attempt",
        text: "You are already logged in!",
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "warning"
      });
      return false;
    }
    const provider = new firebase.auth.TwitterAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(result => {
        router.push(router.currentRoute.query.to || "/");
        commit("UPDATE_USER_INFO", result.user.providerData[0], { root: true });
      })
      .catch(err => {
        payload.notify({
          time: 2500,
          title: "Error",
          text: err.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger"
        });
      });
  },

  // Github Login
  loginWithGithub({ commit, state }, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: "Login Attempt",
        text: "You are already logged in!",
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "warning"
      });
      return false;
    }
    const provider = new firebase.auth.GithubAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(result => {
        router.push(router.currentRoute.query.to || "/");
        commit("UPDATE_USER_INFO", result.user.providerData[0], { root: true });
      })
      .catch(err => {
        payload.notify({
          time: 2500,
          title: "Error",
          text: err.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger"
        });
      });
  },

  registerUser({ dispatch }, payload) {
    // create user using firebase
    firebase
      .auth()
      .createUserWithEmailAndPassword(
        payload.userDetails.email,
        payload.userDetails.password
      )
      .then(
        () => {
          payload.notify({
            title: "Account Created",
            text: "You are successfully registered!",
            iconPack: "feather",
            icon: "icon-check",
            color: "success"
          });

          const newPayload = {
            userDetails: payload.userDetails,
            notify: payload.notify,
            updateUsername: true
          };
          dispatch("login", newPayload);
        },

        error => {
          payload.notify({
            title: "Error",
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        }
      );
  },

  updateUsername({ commit }, payload) {
    payload.user
      .updateProfile({
        displayName: payload.displayName
      })
      .then(() => {
        // If username update is success update in localstorage
        let newUserData = Object.assign({}, payload.user.providerData[0]);
        newUserData.displayName = payload.displayName;
        commit("UPDATE_USER_INFO", newUserData, { root: true });

        // If reload is required to get fresh data after update reload current page
        if (payload.isReloadRequired) {
          router.push(router.currentRoute.query.to || "/");
        }
      })
      .catch(ex => {
        payload.notify({
          time: 8800,
          title: "Error",
          text: ex.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger"
        });
      });
  },

  // login admin
  loginJWT({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .login(payload.userDetails.email, payload.userDetails.password)
        .then(response => {
          if (response.data) {
            localStorage.setItem("user", JSON.stringify(response.data.data));

            // Set accessToken and refresh token
            localStorage.setItem("accessToken", response.data.data.token.accessToken);
            localStorage.setItem("refreshToken", response.data.data.token.refreshToken);
            localStorage.setItem("tokenExpiry", response.data.data.token.accessTokenExpiresIn);
            localStorage.setItem("loggedIn", "true");

            //set remember me
            if (payload.rememberMe === true) {
              Vue.$cookies.config("30d");
              Vue.$cookies.set("rememberMe", payload.rememberMe);
              Vue.$cookies.set("email", payload.userDetails.email);
              Vue.$cookies.set("password", payload.userDetails.password);
            } else {
              Vue.$cookies.remove("rememberMe");
              Vue.$cookies.remove("email");
              Vue.$cookies.remove("password");
            }

            // Update user details
            commit("UPDATE_USER_INFO", response.data.data, { root: true });

            // Set bearer token in axios
            commit("SET_BEARER", response.data.data.token.accessToken);

            resolve(response);
          } else {
            reject({ message: response.data.message });
          }
        })
        .catch(({ response }) => {
          reject({ message: response.data.message });
        });
    });
  },

  registerUserJWT({ commit }, payload) {
    const {
      displayName,
      email,
      password,
      confirmPassword,
      contactNumber,
      userType
    } = payload.userDetails;

    return new Promise((resolve, reject) => {
      // Check confirm password
      if (password !== confirmPassword) {
        reject({ message: "Password doesn't match. Please try again." });
      }
      if (userType === "franchise") {
        jwt
          .registerFranchise(payload.userDetails)
          .then(response => {
            router.push(router.currentRoute.query.to || "/");
            localStorage.setItem("accessToken", response.data.accessToken);
            commit("UPDATE_USER_INFO", response.data.userData, { root: true });
            resolve(response);
          })
          .catch(ex => {
            reject(ex);
          });
      } else {
        jwt
          .registerUser(displayName, email, password, userType)
          .then(response => {
            router.push(router.currentRoute.query.to || "/");
            localStorage.setItem("accessToken", response.data.accessToken);
            commit("UPDATE_USER_INFO", response.data.userData, { root: true });
            resolve(response);
          })
          .catch(ex => {
            reject(ex);
          });
      }
    });
  },

  fetchAccessToken() {
    return new Promise((resolve, reject) => {
      jwt
        .refreshToken()
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          if (localStorage.getItem("accessToken")) {
            clearLocalStorage();
            router.push(router.currentRoute.query.to || "/");
          }
          reject(error);
        });
    });
  },

  resetPassword({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .resetPassword(payload.userDetails.password, payload.userDetails.token)
        .then(response => {
          if (response.status == httpStatusCode.OK) {
            resolve(response);
          } else {
            reject({ message: response.data.message });
          }
        })
        .catch(({ response }) => {
          reject({ message: response.data.message });
        });
    });
  },

  resetPasswordByAdmin({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .resetAdminPassword(
          payload.userDetails.password,
          payload.userDetails.token
        )
        .then(response => {
          if (response.status == httpStatusCode.OK) {
            resolve(response);
          } else {
            reject({ message: response.data.message });
          }
        })
        .catch(({ response }) => {
          reject({ message: response.data.message });
        });
    });
  },

  /**
   *  forgot passowrd
   * @param commit
   * @param payload
   * @returns {Promise<unknown>}
   */
  forgotPwd({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .forgotPasswordAdmin(payload.userDetail.email)
        .then(response => {
          if (response.data) {
            resolve(response);
          } else {
            reject({ message: response.data.message });
          }
        })
        .catch(({ response }) => {
          reject({ message: response.data.message });
        });
    });
  },

  /**
   * admin verification mail resend
   * @param commit
   * @param payload
   * @returns {Promise<unknown>}
   */
  resendVerificationMail({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .resendVerificationMailAdmin(payload.userDetail.email)
        .then(response => {
          if (response.data) {
            resolve(response);
          } else {
            reject({ message: response.data.message });
          }
        })
        .catch(({ response }) => {
          reject({ message: response.data.message });
        });
    });
  },
  changeUserPassword({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .changePassword(
          payload.userDetails.currentPassword,
          payload.userDetails.newPassword
        )
        .then(response => {
          // If there's user data in response
          if (response.status == httpStatusCode.OK) {
            resolve(response);
          } else {
            reject({ message: response.data.message });
          }
        })
        .catch(({ response }) => {
          reject({ message: response.data.message });
        });
    });
  },

  checkTokenExpiry({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .checkTokenExpiry(payload.token)
        .then(response => {
          if (response.status == httpStatusCode.OK) {
            resolve(response);
          } else {
            reject({ message: response.data.message });
          }
        })
        .catch(({ response }) => {
          reject({ message: response.data.message });
        });
    });
  },

  isResetTokenExpired({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .checkResetTokenExpiry(payload.token)
        .then(response => {
          if (response.status === httpStatusCode.OK) {
            resolve(response);
          } else {
            reject({ message: response.data.message });
          }
        })
        .catch(({ response }) => {
          reject({ message: response.data.message });
        });
    });
  },

  verifyMail({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .verifyMail(payload.token)
        .then(response => {
          if (response.status == httpStatusCode.OK) {
            resolve(response);
          } else {
            reject({ message: response.data.message });
          }
        })
        .catch(({ response }) => {
          reject({ message: response.data.message });
        });
    });
  },

  verifyAccount({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/user/verifyAccount", payload)
        .then(res => {
          return resolve(res);
        })
        .catch(ex => {
          return reject(ex.response);
        });
    });
  }
};
