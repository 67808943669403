/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import App from "./App.vue";
import { differenceInYears } from "date-fns";

// Vuesax Component Framework
import Vuesax from "vuesax";
import "material-icons/iconfont/material-icons.css"; //Material Icons
import "vuesax/dist/vuesax.css"; // Vuesax
// axios
import axios from "./axios.js";
// API Calls
import "./http/requests";


// Theme Configurations
import "../themeConfig.js";



// Globally Registered Components
import "./globalComponents.js";

// Styles: SCSS
import "./assets/scss/main.scss";

// Tailwind
import "@/assets/css/main.css";

// Vue Router
import router from "./router";

// Sentry logging
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  environment: process.env.VUE_APP_SENTRY_ENV,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [process.env.VUE_APP_SENTRY_ORIGINS, /^\//],
    }),
  ],
  tracesSampleRate: parseFloat(process.env.VUE_APP_SENTRY_RATE),
  ignoreErrors: [
    'Error',
    /^401$/,
    /^410$/,
  ],
});

// Vuex Store
import store from "./store/store";



// Vuexy Admin Filters
import "./filters/filters";


// VeeValidate
import VeeValidate from "vee-validate";

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from "vue2-hammer";
// PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
// Chart
// import Donut from "vue-css-donut-chart";
// import "vue-css-donut-chart/dist/vcdonut.css";
import VueApexCharts from "vue-apexcharts";
import MoneySpinner from 'v-money-spinner';

Vue.use(Vuesax);


Vue.prototype.$http = axios;
Vue.prototype.$appName = process.env.VUE_APP_NAME;


const VueScrollTo = require("vue-scrollto");
Vue.use(VueScrollTo);

Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

VeeValidate.setMode("eager");

// validator checks if age is 18 years

VeeValidate.Validator.extend("18-years-age", {
  getMessage: (_field) => "Must be over 18 to proceed",
  validate: (value) => {
    if (differenceInYears(new Date(), new Date(value)) >= 18) {
      return true;
    }
    return false;
  },
});

VeeValidate.Validator.extend("phoneFormat", {
  getMessage: () => "The phone number format is invalid.",
  validate: (value) =>
    value.match(
      /^(^04\(?\d{2}\)?[\s.-]\d{3}[\s.-]\d{3})|(^1300[\s.-]\d{3}[\s.-]\d{3})|(^1800[\s.-]\d{3}[\s.-]\d{3})|(^13[\s.-]\d{2}[\s.-]\d{2}$)|(^02[\s.-]\d{4}[\s.-]\d{4})|(^03[\s.-]\d{4}[\s.-]\d{4})|(^07[\s.-]\d{4}[\s.-]\d{4})|(^08[\s.-]\d{4}[\s.-]\d{4})/
    ) !== null,
});

VeeValidate.Validator.extend("urlFormat", {
  getMessage: () => "The website field is not a valid url.",
  validate: (value) =>
    value.match(
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
    ) !== null,
});

// custom directive to make button rounded

Vue.directive("round", {
  bind(el, binding, vnode) {
    el.style.borderRadius = "30px";
  },
});

VeeValidate.Validator.localize("en", {
  custom: {
    password: {
      regex:
        "Password must contain at least 8 characters including one uppercase letter,one lowercase letter and one number.",
    },
  },
});

VeeValidate.Validator.extend("surchargeValidation", {
  getMessage: (field, limit) => `The ${field} should be less than ${limit} %.`,
  validate: (value, limit) => parseFloat(value) <= parseFloat(limit),
});

//custom for bankAccounts.vue , should be moved to the same file
VeeValidate.Validator.extend("atLeastOneChecked", {
  getMessage: (isUsedForSettlement) => `Select the purpose of this account.`,
  validate: (isUsedForSettlement, isUsedForBilling) => {
    var validation = false;
    if (isUsedForSettlement || isUsedForBilling[0] === "true") {
      validation = true;
    }
    return validation;
  },
});

// abn validation
VeeValidate.Validator.extend('abn', {
  getMessage: field => 'The ' + field + ' must be 11 digits.',
  validate: value => value.replaceAll(" ", "").length !== 11
});


// formats currency as $123,56.67
Vue.mixin({
  methods: {
    formatCurrency(value) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(value);
    },
    replaceLegalContent(str, mix) {
      const RGX = /{{(.*?)}}/g;
      return str.replace(RGX, (x, key, y) => {
        x = 0;
        y = mix;
        key = key.trim().split(".");
        while (y && x < key.length) {
          y = y[key[x++]];
        }
        return y != null ? y : "";
      });
    },
  },
  computed: {
    isFormDirty() {
      return Object.keys(this.fields).some((key) => this.fields[key].dirty);
    },
    isFormChanged() {
      return Object.keys(this.fields).some((key) => this.fields[key].changed);
    },
    // validateForm() {
    //   return !this.errors.any();
    // }
  },
});

Vue.use(VeeValidate, {
  classes: true,
});



Vue.use(VueHammer);
// Vue.use(Donut);
// Feather font icon
require("./assets/css/iconfont.css");

Vue.use(MoneySpinner);
// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';
import OtpInput from "@bachdgvn/vue-otp-input";

Vue.component("v-otp-input", OtpInput);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
